import { domStorage, globalStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from './page-intro'

export default class GlobalIntro {
  constructor() {
    this.DOM = {
      logo: domStorage.intro.querySelector('.Logo')
    }
    this.setTimeline()
  }

  setTimeline() {
    const { body, intro, header } = domStorage
    const { logo } = this.DOM

    this.tl = gsap
      .timeline({
        delay: 0.2,
        defaults: { ease: 'expo.inOut' },
        onStart: () => {
          body.classList.remove('is--preloading')
          body.classList.remove('is--loading')
        },
        onComplete: () => {
          ScrollTrigger.refresh()

          body.classList.remove('is--animating')
          body.classList.remove('is--intro')

          globalStorage.firstLoad = false

          // Clean styles
          gsap.set(header, { clearProps: 'opacity' })
        }
      })
      .fromTo(logo, { scale: 0.8, opacity: 0 }, { scale: 1.2, opacity: 1, duration: 2  }, 0)
      .to(logo, { scale: 50, duration: 2.5 }, 1.46)
      .to(intro, { background: 'white', duration: 2, onComplete: () => body.removeChild(intro) }, 1.5)
      .fromTo(viewStorage.current, { opacity: 0 }, { opacity: 1, duration: 1, ease: 'power3.out' }, 3)
      .add(pageIntro(0), 3)
      .fromTo(header, { opacity: 0 }, { opacity: 1, ease: 'power3', duration: 1 }, '-=0.75')
  }

  destroy() {
    const { tl } = this

    if (tl) tl.kill()
  }
}
