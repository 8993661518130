import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import LoadMore from '../modules/load-more'

export default class ArchiveRealisations extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      cards: viewStorage.current.querySelectorAll('.RealisationCard')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { cards } = this.DOM

    // 1. Container, 2. Cards, 3. Limit
    if (cards.length) this.loadMore = new LoadMore(viewStorage.current, cards, 6)
  }
  
  onLeaveCompleted() {
    const { loadMore } = this

    if (loadMore) loadMore.destroy()
  }
}
